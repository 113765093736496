import React from 'react';
import { bool, shape, string } from 'prop-types';
import { RecommendationsCarouselSnapped } from '@recommendations-fe/carousel-snapped';
import RecommendationsIconFactory from './icon-factory';
import { RecommendationsCardHorizontalTitleFirst } from './recomendations-card';

const ID = 'carousel_above_seller';

const Recommendations = ({ isPolycard, thousandSeparator, decimalSeparator, featureName, ...props }) => (
  <RecommendationsCarouselSnapped
    Card={RecommendationsCardHorizontalTitleFirst}
    contextProps={{
      type: 'list-card',
      thousandSeparator,
      decimalSeparator,
    }}
    cardProps={{
      polyClassName: 'recos-polycard poly-card--mobile',
    }}
    isPolycard={isPolycard}
    IconFactory={RecommendationsIconFactory}
    {...props}
    minItemsToShow={featureName === ID ? 1 : undefined}
  />
);

Recommendations.propTypes = {
  isPolycard: bool.isRequired,
  decimalSeparator: string.isRequired,
  thousandSeparator: string.isRequired,
  className: string,
  apiresponse: shape({}),
  featureName: string,
};

export default Recommendations;
